<template>
  <modal name="publishBid" transition="pop-out" @opened="opened" class="publishBidPopup--wrapper">
    <div class="publishBidPopup modal"
    >
      <div class="spinnerWrapper" v-if="isLoading">
        <Spinner/>
      </div>
      <div class="publishBidPopup__decor"></div>
      <div class="publishBidPopup__inner" :class="{'loading': isLoading}">
        <div v-if="types.book" class="switcherWrapper">
          <button v-for="button of buttons" :key="button.value" @click="() => changeType(button.value)" class="white-button" :class="{'active': button.value === selectedType}">
            <span class="text">{{ button.label }}</span>
          </button>
        </div>
        <div v-if="selectedType === 'book' && types.book">
<!--          <div class="publishBidPopup__title" v-if="!types.bid && !types.request">Book the fly</div>-->
          <div class="request--text">Would you like to book this flight?</div>
          <div class="request--text booking-price">approx. <b>{{ localisedPrice(basePrice) }}</b></div>
          <div class="buttonsWrapper">
            <button type="button" class="publish__button book" @click="$emit('createBooking')">
              Book
            </button>
          </div>
        </div>
        <div class="publishBidPopup__title" v-if="!types.book && !types.request">BID</div>

        <div v-if="selectedType === 'bid' || !types.book">
          <div v-if="types.bid">
            <div class="currentBidWrapper--popup" v-if="previousBid">
              <span class="currentBid__text">Current BID</span>
              <span class="currentBidValue">{{localisedPrice(previousBid)}}</span>
            </div>
            <div class="form__field-wrapper">
              <input type="text" :placeholder="'Min. ' + localisedPrice(minBid)" v-model="formattedBid" class="form__field js-typeahead" :min="minBid">
            </div>
            <div class="publishBidPopup__tip">
              <span v-if="currentBid < minBid && publishClicked">Minimum updated bid is ${{minBid}}</span>
            </div>
            <div class="splitWithGroupText">
              <span>Willing To Split With Group</span>
            </div>
            <div class="switcherWrapper switcherWrapper--splitWithGroup">
              <button v-for="button of splitWithGroupButtons" :key="button.value" @click="() => splitWithGroup = button.value" class="white-button" :class="{'active': button.value === splitWithGroup}">
                <span class="text">{{ button.label }}</span>
              </button>
            </div>
            <button type="button" class="publish__button" @click="this.publishBid">
              Publish
            </button>
          </div>
          <div v-else>
            <div class="request--text">{{ reasons.bid }}</div>
            <div class="buttonsWrapper">
              <button class="thanksPopup__button thanksPopup__button--yes" @click="this.hide">
                <span class="text">Cancel</span>
              </button>
            </div>
          </div>
        </div>

        <div  v-if="selectedType === 'request'">
          <div v-if="types.request">
            <div class="request--text">
              Please confirm that you are committed to
              purchasing this aircraft from the operator at a
              nonrefundable price of approximately <b>{{ localisedPrice(Math.ceil(basePrice * 0.6)) }}</b> in the
              case that we can locate an empty leg match
              on the same day. In the case that you do need
              to cancel this trip, FLYJETS can attempt to
              assist in re-selling it for you.
            </div>
            <div class="buttonsWrapper">
              <button @click="this.confirmRequest" class="thanksPopup__button thanksPopup__button--no">Confirm</button>
              <button class="thanksPopup__button thanksPopup__button--yes" @click="this.hide">
                <span class="text">Cancel</span>
              </button>
            </div>
          </div>
          <div v-else>
            <div class="request--text">{{ reasons.request }}</div>
            <div class="buttonsWrapper">
              <button class="thanksPopup__button thanksPopup__button--yes" @click="this.hide">
                <span class="text">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="modal__close" @click="this.hide">
      <SvgIcon name="close-white"/>
    </button>
  </modal>
</template>

<script>
import './BidRequestEmptyPopup.styl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Spinner from '@/components/common/Spinner/Spinner.vue';

export default {
  name: 'BidRequestEmptyPopup',
  props: ['previousBid', 'basePrice', 'types', 'isLoading', 'isAllButtons', 'reasons'],
  computed: {
    formattedBid: {
      get () {
        return this.currentBid ? `$${this.currentBid}` : '';
      },
      set (newValue) {
        this.currentBid = newValue.replace(/^\$/, '');
      },
    },
  },
  data: () => ({
    publishClicked: false,
    minBid: '',
    currentBid: '',
    selectedType: 'book',
    buttons: [
      {
        label: 'BOOK',
        value: 'book',
      },
      {
        label: 'BID',
        value: 'bid',
      },
      {
        label: 'FLY Empty',
        value: 'request',
      },
    ],
    splitWithGroup: false,
    splitWithGroupButtons: [
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ],
  }),
  methods: {
    show () {
      this.$modal.show('publishBid')
    },
    hide () {
      this.publishClicked = false
      this.$modal.hide('publishBid')
      this.minBid = ''
      this.currentBid = ''
      this.selectedType = 'book'
    },
    publishBid () {
      this.publishClicked = true
      if (this.currentBid >= this.minBid) {
        const currentBid = this.currentBid
        const splitWithGroup = this.splitWithGroup
        this.publishClicked = false
        this.splitWithGroup = false
        this.minBid = ''
        this.currentBid = ''
        this.selectedType = 'book'
        this.$emit('publishBid', currentBid, splitWithGroup)
      }
    },
    confirmRequest () {
      this.$emit('confirmRequest')
    },
    localisedPrice (price) {
      if (price) {
        return price.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
      }

      return ''
    },
    opened () {
      if (!this.types.book) {
        this.selectedType = 'bid'
      }

      const val = this.previousBid ? this.previousBid + this.previousBid * 0.02 : this.basePrice * 0.8;
      this.minBid = Math.ceil(val)
      // this.currentBid = this.minBid
    },
    changeType (type) {
      this.selectedType = type
    },
  },
  created () {
    this.$parent.$on('showModal', this.show)
  },
  components: {
    Spinner,
    SvgIcon,
  },
}
</script>
